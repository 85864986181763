import { defineComponent as _defineComponent } from 'vue'
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "container q-py-xl q-my-xl" }
const _hoisted_2 = { class: "row q-col-gutter-lg" }
const _hoisted_3 = { class: "col-12 col-sm-6" }
const _hoisted_4 = { class: "col-12 col-sm-6" }
const _hoisted_5 = { class: "q-item__section column q-item__section--main justify-center" }
const _hoisted_6 = { class: "text-weight-light text-h4" }
const _hoisted_7 = ["innerHTML"]

import {computed, onMounted} from 'vue';
import {useMainStore} from 'stores/main';

interface Faq {
  question: string
  answer: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'StartFaqs',
  setup(__props) {


const mainStore = useMainStore()

const faq = computed<Array<Faq>>(() => {
  return mainStore.faqs || []
})

onMounted(() => {
  return mainStore.fetchFAQs()
})

return (_ctx: any,_cache: any) => {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_list = _resolveComponent("q-list")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(["text-h2 text-weight-bolder", {'text-center': _ctx.$q.screen.lt.sm}])
        }, "FAQ - Häufig gestellte Fragen ", 2)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_q_list, { separator: "" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(faq.value, (item, index) => {
              return (_openBlock(), _createBlock(_component_q_expansion_item, { key: index }, {
                header: _withCtx(() => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h3", _hoisted_6, _toDisplayString(item.question), 1)
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_q_card, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_q_card_section, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", {
                            innerHTML: item.answer
                          }, null, 8, _hoisted_7)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ])
    ])
  ]))
}
}

})