<template>
  <div class="c-fancy-background full-width full-height rounded-borders bordered flex flex-center">
    <div class="wrapper relative-position">
      <div class="center absolute-center">
        <div style="margin-top: -25px">🚐</div>
      </div>
      <!-- Rotating Emojis -->
      <div
v-for="(emoji, index) in emojis" :key="index" class="items absolute-center"
           :style="{ animationDelay: `${10 / emojis.length * index}s`}">
        <span class="emoji" :style="{ animationDelay: `${10 / emojis.length * index}s`}">{{ emoji }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from 'vue';

// List of emojis to rotate
const emojis = ref(['🔧', '🏬', '🔥', '🛠️', '✅', '🛒', '📄', '📜', '🤝']);
</script>

<style scoped lang="scss">
.wrapper {
  width: 200px;
  height: 200px;
  position: relative;
}

.center {
  font-size: 5rem;
  z-index: 2;
}

.items {
  font-size: 2.2rem;
  display: inline-block;
  transform-origin: 0 0;
  animation: orbit-rotation linear infinite 10s;
}

.emoji {
  display: inline-block;
  transform-origin: 50% 50%;
  animation: rotate infinite 10s linear;
}


@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

/* Keyframes to handle rotation */
@keyframes orbit-rotation {
  from {
    transform: rotate(0deg) translateX(60px);
  }
  to {
    transform: rotate(360deg) translateX(60px);
  }
}
</style>
