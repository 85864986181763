import script from "./StartFaqs.vue?vue&type=script&setup=true&lang=ts"
export * from "./StartFaqs.vue?vue&type=script&setup=true&lang=ts"

const __exports__ = script;

export default __exports__
import QList from 'quasar/src/components/item/QList.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../../../node_modules/@quasar/app-webpack/lib/webpack/runtime.auto-import.js";
qInstall(script, 'components', {QList,QExpansionItem,QItem,QCard,QCardSection});
