<template>
  <div class="c-fancy-background full-width full-height rounded-borders bordered flex flex-center overflow-hidden">
    <div class="magnifying-glass"></div>
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped>
@keyframes magnifyMove {
  0%, 100% {
    transform: translate(0, 0) rotate(0);
  }

  40% {
    transform: translate(-20px, 0) rotate(10deg);
  }
  70% {
    transform: translate(0, 0) rotate(-30deg);
  }
}

@keyframes moveAndMagnify {
  0%, 30% {
    transform: translate(0, 0) scale(1);
    animation-timing-function: ease-in-out;
  }
  40%, 70% {
    transform: translate(70px, 10px) scale(1.2); /* First move and slight magnify */
    animation-timing-function: ease-in-out;
  }
  80%, 90% {
    transform: translate(-50px, -30px) scale(1); /* Second move */
    animation-timing-function: ease-in-out;
  }
  100% {
    transform: translate(0, 0) scale(1); /* Back to start */
    animation-timing-function: ease-in-out;
  }
}

.magnifying-glass {
  font-size: 3rem;
  display: inline-block;
  animation: moveAndMagnify 8s infinite ease-in-out;
}

.magnifying-glass::before {
  content: "🔍";
  display: inline-block;
  animation: magnifyMove 10s infinite;
  transform-origin: 100% 100%;
  font-size: 5rem;
}
</style>
