<template>
  <div class="c-fancy-background rounded-borders bordered relative-position overflow-hidden">
    <div class="absolute full-height" style="right: 10%">
      <div class="flex flex-center no-wrap c-wrapper full-height">
        <div v-for="i in 6" :key="i" class="box">📦</div>
        <div class="new-box">📦</div>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped lang="scss">
.c-wrapper {
  font-size: 4rem;
  animation: infinite moveLeft 3s ease-in-out;
}

@keyframes moveLeft {
  0%, 75% {
    transform: translateX(0);
  }
  85%, 100% {
    transform: translateX(-80px);
  }
}

.new-box {
  animation: scaleIn 3s infinite ease-in-out;
}

@keyframes scaleIn {
  0% {
    transform: scale(4);
    opacity: 0;
  }
  10%, 100% {
    opacity: 1;
  }
  18% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(1.1);
  }
  23%, 100% {
    transform: scale(1);
  }
}
</style>
