import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "wrapper"
}
const _hoisted_2 = { class: "q-pa-md" }
const _hoisted_3 = { class: "q-pa-md" }
const _hoisted_4 = { class: "swiper-slide" }
const _hoisted_5 = { class: "q-pa-md bg-grey-3 relative-position" }
const _hoisted_6 = { class: "absolute-center full-width" }
const _hoisted_7 = { class: "text-center" }
const _hoisted_8 = { class: "q-mt-md" }

import {onMounted, ref} from 'vue';
import BusinessApi from 'src/api/business'
import DealerSearchListItem
  from 'src/apps/search/components/searchPageComponents/searchType/listItems/DealerSearchListItem.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'CSwiperCardsServices',
  setup(__props) {

const services = ref()
const loading = ref(true)
const loadingItems = ref(true)
const swiperEle = ref<any | null>(null)


async function fetchServices() {
  const resp = await BusinessApi.fetchBusinessAccountStartCards()
  services.value = resp.data?.splice(0, 6)
}

onMounted(() => {
  loading.value = false

  fetchServices().catch(console.error).finally(() => {
    loadingItems.value = false
  })
})



return (_ctx: any,_cache: any) => {
  const _component_q_btn = _resolveComponent("q-btn")!

  return (!loading.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("swiper-container", {
          ref_key: "swiperEle",
          ref: swiperEle,
          effect: "cards",
          class: "swiper"
        }, [
          (loadingItems.value)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, _renderList(3, (i) => {
                return _createElementVNode("swiper-slide", {
                  key: i,
                  class: "swiper-slide"
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(DealerSearchListItem, { loading: true })
                  ])
                ])
              }), 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(services.value, (service) => {
                  return (_openBlock(), _createElementBlock("swiper-slide", {
                    key: service.uuid,
                    class: "swiper-slide"
                  }, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(DealerSearchListItem, { "dealer-data": service }, null, 8, ["dealer-data"])
                    ])
                  ]))
                }), 128)),
                _createElementVNode("swiper-slide", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(DealerSearchListItem, {
                      style: {"visibility":"hidden"},
                      "dealer-data": services.value?.length ? services.value[0] : undefined,
                      "show-slider": false
                    }, null, 8, ["dealer-data"]),
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "text-muted" }, [
                          _createElementVNode("div", { style: {"font-size":"2rem"} }, "🤩"),
                          _createElementVNode("div", null, "Finde den passenden Dienstleister!")
                        ], -1)),
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_q_btn, {
                            color: "dark",
                            rounded: "",
                            unelevated: ""
                          }, {
                            default: _withCtx(() => _cache[0] || (_cache[0] = [
                              _createTextVNode("Zum den Dienstleistern")
                            ])),
                            _: 1
                          })
                        ])
                      ])
                    ])
                  ])
                ])
              ], 64))
        ], 512)
      ]))
    : _createCommentVNode("", true)
}
}

})